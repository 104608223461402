import {
    Route,
    Routes,
} from "react-router-dom";
import ChooseUser from "./containers/ChooseUser";
import Dashboard from './containers/Dashboard'
import EditLaw from "./containers/Dashboard/components/EditLaw";
import Laws from "./containers/Dashboard/components/Laws";
import References from "./containers/Dashboard/components/References";
import ConceptNote from "./containers/Dashboard/components/ConceptNote";
import { useEffect, useState } from "react";
import ConsulationPaper from "./containers/Dashboard/components/ConsulationPaper";
import Publish from "./containers/Dashboard/components/Publish";
import ForgotPassword from "./containers/ForgotPassword";
import PasswordReset from "./containers/PasswordReset";
import ResetLinkSent from "./containers/ResetLinkSent";
import Signin from "./containers/Signin";
import Signup from "./containers/Signup";
import SignupSuccess from "./containers/SignupSuccess";
import LawReview from "./containers/Dashboard/components/LawReview";
import Publications from "./containers/Dashboard/components/Publications";
import Home from "./containers/Home";
import Home2 from "./containers/Home2";
import AllPubications from "./containers/AllPubications";
import LawsOfUganda from "./containers/AllPubications/LawsOfUganda";
import ReviewPublication from "./containers/AllPubications/ReviewPublication";
import Cart from "./containers/AllPubications/Cart";
import ChooseUserType from "./containers/ChooseUserType";
import CartPayment from "./containers/CartPayment";
import Search from "./containers/Search";
import AdvancedSearch from "./containers/AdvancedSearch";
import TreatyDesc from "./containers/TreatyDesc";
import PaymentSuccess from "./containers/PaymentSuccess";
import Dashboard2 from "./containers/Dashboard";
import Analytics from "./containers/Analytics";
import UserManagement from "./containers/UserManagement";
import DashboardInfo from "./containers/DashboardInfo";
import Constitution from "./containers/AllPubications/Constitution";
import Reports from "./containers/AllPubications/Report";
import OtherPublications from "./containers/AllPubications/OtherPublications";
import SimplifiedLawsUganda from "./containers/AllPubications/SimplifiedLawsOfUganda";
import DocumentViewerComponent from "./containers/DocumentViewerComponent";
import DocumentViewer from "./containers/DocumentViewer";
import PreviewVolume from "./containers/AllPubications/PreviewVolume";
import PreviewAct from "./containers/AllPubications/PreviewAct";
import DeleteAccount from "./containers/DeleteAccount";
import NotFound from "./containers/NotFound";
import PreviewSimplifiedLaw from "./containers/AllPubications/PreviewSimplifiedLaw";
import DownloadApp from "./containers/Mobile";
import TermsAndConditions from "./containers/TermsAndConditions";
import GoogleRedirect from "./containers/GoogleRedirect";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import ContactUs from "./containers/ContactUs";
import Prns from "./containers/Prns";
import PeopleManagement from "./containers/PeopleManagement";
import MaintenancePage from "./containers/MaintenancePage";
import PreviewReport from "./containers/AllPubications/PreviewReport";
import PreviewOtherPublications from "./containers/AllPubications/PreviewOtherPublications";
import PreviewConstitution from "./containers/AllPubications/PreviewConstitution";
import PasswordResetSuccess from "./containers/PasswordResetSuccess";
import AddEnterpriseUser from "./containers/AddEnterpriseUser";
// import Sample from "./containers/Sample";

const userAgent = navigator.userAgent.toLowerCase();
const _isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);
const _isIOS = /iphone|ipad|ipod/g.test(userAgent);
const _isAndroid = /android/g.test(userAgent);
const _isWindows = /windows phone/g.test(userAgent);

const Router = () => {
    const [isMobile, setIsMobile] = useState(_isMobile)
    const [os, setOs] = useState(_isIOS ? 'iOS' : _isAndroid ? 'Android' : _isWindows ? 'Windows' : 'Unknown');

    if (isMobile) {
        return (
            <Routes>
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/reset-password-success" element={<PasswordResetSuccess />} />
                <Route path="*" element={<DownloadApp os={os} />} />
            </Routes>
        )
    }

    return (
        <Routes>
            {/* <Route path="sample" element={<Sample />} /> */}
            {/* <Route path="*" element={<MaintenancePage />} /> */}
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup-success" element={<SignupSuccess />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/reset-password-success" element={<PasswordResetSuccess />} />
            <Route path="/dashboard" element={<Dashboard />}>
                <Route path="analytics" element={<Analytics />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="publications" element={<Publications />} />
                <Route path="info" element={<DashboardInfo />} />
                <Route path="prns" element={<Prns />} />
                <Route path="people-management" element={<PeopleManagement />} />                
                {/* <Route path="add-enterprise-user" element={<AddEnterpriseUser />} />                 */}
                <Route path="*" element={<div />} />
            </Route>
            <Route path="/pdfpreview" element={<DocumentViewerComponent />} />
            <Route path="/choose-user" element={<ChooseUser />} />
            <Route path="/choose-user-type" element={<ChooseUserType />} />
            <Route path="/" element={<Home2 />} />
            <Route path="/all-publications" element={<AllPubications />} />
            <Route path="/all-publications/:id" element={<ReviewPublication />} />
            <Route path="/laws-of-uganda/:id" element={<PreviewVolume />} />
            <Route path="/simplified-laws-of-uganda/:id" element={<PreviewSimplifiedLaw />} />
            <Route path="/laws-of-uganda/:lawsId/acts/:id" element={<PreviewAct />} />
            <Route path="/acts/:id" element={<PreviewAct />} />
            <Route path="/laws-of-uganda" element={<LawsOfUganda />} />
            <Route path="/constitution" element={<Constitution />} />
            <Route path="/constitution/:id" element={<PreviewConstitution />} />
            <Route path="/report" element={<Reports />} />
            <Route path="/report/:id" element={<PreviewReport />} />
            <Route path="/other-publication" element={<OtherPublications />} />
            <Route path="/other-publication/:id" element={<PreviewOtherPublications />} />
            <Route path="/simplified-laws-of-uganda" element={<SimplifiedLawsUganda />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cart/payment" element={<CartPayment />} />
            <Route path="/cart/payment-success" element={<PaymentSuccess />} />
            <Route path="/reset-link-sent" element={<ResetLinkSent />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/destroy-account" element={<DeleteAccount />} />
            <Route path="/api/auth/google/callback" element={<GoogleRedirect />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Router
