import React, { useEffect, useState } from 'react';
import { Spin, Table, Input, Button } from 'antd';
import axios from 'axios';
import { FiRefreshCcw } from "react-icons/fi";
import { useAuth } from '../Auth';
import { API_URL, BASE_URL } from '../envs';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id'
    },
    {
        title: 'PRN',
        dataIndex: 'prn'
    },
    {
        title: 'Amount',
        dataIndex: 'prnAmount'
    },
    {
        title: 'Status Code',
        dataIndex: 'StatusCode'
    },
    {
        title: 'Status Description',
        dataIndex: 'StatusDesc'
    },
    {
        title: 'Expiry',
        dataIndex: 'PaymentExpiryDate'
    },
    {
        title: 'User Name',
        dataIndex: 'userName',
    },
    {
        title: 'Phone',
        dataIndex: 'phoneNumber',
    }
]

const Prns = () => {

    const [prns, setPrns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const { userData } = useAuth();

    useEffect(() => {
        fetchPrns();
    }, [userData, currentPage, searchQuery]);

    const fetchPrns = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_URL}/prns`, {
                params: {
                    sort: 'createdAt:desc',
                    pagination: {
                        page: currentPage - 1,
                        pageSize: 10
                    },
                    filters: {
                        prn: {
                            $contains: searchQuery
                        }
                    }
                },
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            const _prns = await response.data?.data;
            const formattedPrns = _prns.map(prn => {
                return {
                    id: prn.id,
                    ...prn.attributes
                }
            });

            try {
                const prnStatuses = await Promise.allSettled(formattedPrns.map(prn => {
                    return axios.post(`${BASE_URL}/prn_status`, {
                        "prn": prn.prn
                    })
                }));
                const formattedPrnStatuses = prnStatuses.map(prnStatus => {
                    return prnStatus?.value?.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult
                });

                const mergedArray = formattedPrns.map((prn) => {
                    const index = formattedPrnStatuses.findIndex(prnStatus => `${prnStatus.PRN}` === prn.prn);
                    const prnStatus = formattedPrnStatuses[index];

                    return {
                        ...prn,
                        ...prnStatus
                    }
                });

                setPrns(mergedArray);
            } catch (error) {
                console.log("ERROR FETCHING PRN STATUSES:", error);
                setPrns(formattedPrns);
            }

            const totalPrns = response.data.meta.pagination.total;
            const pageSize = response.data.meta.pagination.pageSize;
            setTotalPages(Math.ceil(totalPrns / pageSize));

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    return (
        <div className="mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <div className="flex justify-between mb-3">
                <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full">
                    PRNs
                </h1>
                <div className="flex gap-2">
                    <Input
                        placeholder="Search PRNs"
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    <Button
                        disabled={loading}
                        className="py-1 px-2 rounded flex items-center gap-2 border border-slate-300"
                        onClick={() => fetchPrns()}
                    >
                        <FiRefreshCcw />
                        <span className='text-base'>Refresh</span>
                    </Button>
                </div>
            </div>

            <Spin spinning={loading} tip="Loading PRNs...">
                <Table
                    columns={columns}
                    dataSource={prns}
                    pagination={{
                        current: currentPage,
                        pageSize: 10,
                        total: totalPages * 10,
                        onChange: handlePageChange,
                    }}
                />
            </Spin>
        </div>
    );

};

export default Prns;