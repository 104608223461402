import { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import { getSubscription } from '../containers/Signup';

const useInviteUser = (API_URL, userData, inviteUrl, fetchUsers, fetchInvitees, closeModal) => {
    const [loader, setLoader] = useState(false);

    const handleSubmit = async (email, multi=false) => {

        console.log("handleSubmit email:", email)

        try {
            setLoader(true);
            const query = qs.stringify({
                filters: {
                    email: {
                        '$eq': email
                    }
                }
            }, {
                encodeValuesOnly: true,
            });

            const resp = await axios.get(`${API_URL}/users?${query}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });

            const user = resp.data[0];
            let redirectUrl = 'https://publications.ulrc.go.ug/signin';
            if (user) {
                await axios.put(`${API_URL}/users/${user.id}`, {
                    subscribedTo: `${userData?.user?.id}`,
                }, {
                    headers: {
                        Authorization: `Bearer ${userData?.jwt}`,
                    },
                });
                getSubscription(
                    { email: userData?.user?.email },
                    email
                );
            } else {
                redirectUrl = 'https://publications.ulrc.go.ug/signup';

                await axios.post(`${API_URL}/invitations`, {
                    data: {
                        InviterID: `${userData?.user?.id}`,
                        InviteeEmail: email
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${userData?.jwt}`,
                    },
                });
            }

            await axios.post(inviteUrl, {
                userData: userData?.user,
                email,
                redirectUrl
            });

        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
            if(!multi){
                await fetchUsers();
                await fetchInvitees();
                closeModal();
                toast.success('User Invitation sent Successfully');
            }
        }
    };

    return { handleSubmit, loader };
};

export default useInviteUser;