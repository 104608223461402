import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../Auth';
import axios from 'axios';
import { API_URL } from '../envs';
import { Popconfirm, Space, Spin, Table, Button } from 'antd'
import { toast } from 'react-toastify';
import qs from 'qs';
import { getSubscription } from './Signup';
import Papa from 'papaparse';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import useInviteUser from '../hooks/useInviteUser';
import { encodeQueryData } from '../utils/methods';

// const inviteUrl = process.env.NODE_ENV === 'production' ? 'https://govnet-api-proxy.vercel.app/invite' : 'http://localhost:3003/invite';
const inviteUrl = 'https://govnet-api-proxy.vercel.app/invite';

const InviteUserDialog = ({ closeModal, fetchUsers, fetchInvitees }) => {
    // const [loader, setLoader] = useState(false)

    const { userData } = useAuth()
    const [email, setEmail] = useState('');
    const { handleSubmit, loader } = useInviteUser(API_URL, userData, inviteUrl, fetchUsers, fetchInvitees, closeModal);

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-slate-900/75 flex justify-center items-center">
            <form
                className="bg-white rounded-lg w-[500px] max-w-full max-h-full overflow-y-auto"
                onSubmit={handleSubmit}
            >
                <div className="flex justify-between items-center px-5 py-4 border-b border-[color:var(--lighter-grey-strokes,#ECEDED)]">
                    <div className="text-neutral-800 text-lg font-semibold">
                        Invite New User
                    </div>
                    <div
                        className="text-neutral-800 text-lg font-semibold cursor-pointer"
                        onClick={closeModal}
                    >
                        X
                    </div>

                </div>
                <div className="px-5 py-4">
                    <div className="text-neutral-800 text-sm leading-5 mb-2">
                        Email
                    </div>
                    <input
                        type="email"
                        name='email'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        placeholder='Enter User Email'
                        title="Please enter a valid email address"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        className="border border-[color:var(--lighter-grey-strokes,#ECEDED)] rounded-lg w-full px-3 py-2 text-sm leading-5"
                    />
                </div>
                <div className="px-5 py-4 flex justify-end">
                    <button
                        disabled={loader}
                        onClick={() => handleSubmit(email)}
                        className="bg-bgBlue text-white py-2 px-5 rounded-lg"
                    >
                        {loader ? "Wait..." : "Invite"}
                    </button>
                </div>
            </form>
        </div>
    )
}

// id, name, email, confirmed


function UserManagement() {

    const [showInviteUserDialog, setShowInviteUserDialog] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [disableAdd, setDisableAdd] = React.useState(false)
    const [invitees, setInvitees] = React.useState([])
    const { userData, subscriptionData } = useAuth()

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Confirmed',
            dataIndex: 'confirmed',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Delete user"
                        description="Are you sure to delete this user?"
                        onConfirm={() => {
                            console.log("RECORD:", record)
                            deleteUser(record)
                        }}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ]


    const deleteInvitation = async (user) => {
        try {
            setLoading(true)
            await axios.delete(`${API_URL}/invitations/${user.id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const deleteUser = async (user) => {
        
        if (!JSON.parse(user.confirmed) && user.name === "N/A") {
            try {                
                await deleteInvitation(user)
            } catch (error) {
                console.log("ERROR:", error)
            } finally {
                await fetchUsers();
                await fetchInvitees();
            }
        }

        if (JSON.parse(user.confirmed) && Number(user.subscribedTo) === userData?.user?.id) {
            // deleteSubscription(user) 
            try {                
                await deleteSubscriber(user)
            } catch (error) {
                console.log("ERROR:", error)
            } finally {
                await fetchUsers();
                await fetchInvitees();
            }
        }
    }

    const deleteEntry = async (entry) => {
        try {
            await axios.delete(`${API_URL}/subscriptions/${entry.id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    const deleteSubscriber = async (user) => {
        
        try {
            setLoading(true)
            await axios.put(`${API_URL}/users/${user.id}`, { subscribedTo: null });

            try {
                const filters = {
                    'filters[PRN][$eq]': subscriptionData.PRN,
                    'filters[userEmail][$eq]': user?.email,
                }
                const query = encodeQueryData(filters)

                const resp = await axios.get(`${API_URL}/subscriptions?${query}`);
                const entries = resp.data.data;

                for (const subscription of entries) {
                    await deleteEntry(subscription)
                }
                
                // Search in invitees list for email and delete subsequent entries
                const InviteeFilters = {                    
                    'filters[InviteeEmail][$eq]': user?.email,
                }
                const inviteeQuery = encodeQueryData(InviteeFilters)
                const inviteeResp = await axios.get(`${API_URL}/invitations?${inviteeQuery}`);
                const invitees = inviteeResp.data.data;
                for (const invitee of invitees) {
                    await deleteInvitation(invitee)
                }

            } catch (error) {
                console.log(error);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        (async () => {
            await fetchUsers();
            await fetchInvitees();
        })()
    }, [])

    const fetchInvitees = async () => {
        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    InviterID: {
                        '$eq': userData?.user?.id
                    }
                }
            }, {
                encodeValuesOnly: true,
            });
            const response = await axios.get(`${API_URL}/invitations?${query}&pagination[start]=0&pagination[limit]=60`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            const data = response?.data?.data || []
            const formattedData = data?.map(item => ({ ...item?.attributes, id: item?.id })).filter((obj, index, self) =>
                self.findIndex((o) => o.InviteeEmail === obj.InviteeEmail) === index
            )
            setInvitees(formattedData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const fetchUsers = async () => {
        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    subscribedTo: {
                        '$eq': userData?.user?.id
                    }
                }
            }, {
                encodeValuesOnly: true,
            });
            const response = await axios.get(`${API_URL}/users?${query}&pagination[start]=0&pagination[limit]=60`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            const _users = await response.data;
            const filteredUsers = _users?.map(user => {
                return {
                    ...user,
                    name: user.username,
                    confirmed: `${user.confirmed}`,
                }
            });
            setUsers(filteredUsers);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const { handleSubmit, loader } = useInviteUser(API_URL, userData, inviteUrl, fetchUsers, fetchInvitees, () => { });

    useEffect(() => {
        if (invitees.length > 0) {
            const newUsers = invitees.filter(invitee => !users.some(user => user.email === invitee.InviteeEmail));
            setInvitees([])
            const _newUsers = newUsers.map(item => ({
                name: "N/A",
                email: item?.InviteeEmail,
                confirmed: false,
                id: item?.id
            }))
            setUsers(prevUsers => [...prevUsers, ..._newUsers]);
        }
    }, [invitees, users])

    useEffect(() => {
        const str = subscriptionData?.numberOfUsers || "0"
        const num = str.match(/\d+/)[0];
        const numericValue = parseInt(num, 10);

        if (users.length >= (numericValue - 1)) {
            setDisableAdd(true);
        } else {
            setDisableAdd(false);
        }

    }, [subscriptionData, users])

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: async (results) => {
                    const newEmails = results.data.map(user => user.email || user.Email);
                    const maxEmailsToAdd = parseInt(subscriptionData?.numberOfUsers) - (users.length + 1);
                    const emailsToAdd = newEmails.slice(0, maxEmailsToAdd);

                    try {
                        await Promise.all(emailsToAdd.map(async (email) => {
                            const formData = new FormData();
                            formData.append('email', email);
                            await handleSubmit(email, true);
                        }));
                        // setUsers((prevUsers) => [...prevUsers, ...emailsToAdd]);
                    } catch (error) {
                        console.error("Error submitting users:", error);
                    } finally {
                        await fetchUsers();
                        await fetchInvitees();
                        toast.success('User Invitations sent Successfully');
                    }
                },
                error: (error) => {
                    console.error("Error parsing CSV file:", error);
                },
            });
        }
    };

    const downloadTemplate = () => {
        const link = document.createElement('a');
        link.href = '/emails.csv';
        link.download = 'template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const fileInputRef = useRef(null);

    return (
        <div className="mt-14 px-5 max-md:max-w-full max-md:mt-10">
            <div className="flex justify-between mb-3">
                <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full mb-5">
                    Subscription Management
                </h1>
                <div>
                    <span className='font-bold'>{users.length + 1}</span> / <span>{subscriptionData?.numberOfUsers || 0}</span>
                </div>
            </div>

            <Spin spinning={loading} tip="Loading Users...">
                <Table
                    // rowSelection={{
                    //     type: 'checkbox',
                    // }}
                    columns={columns}
                    dataSource={users}
                />
            </Spin>

            {
                !disableAdd &&
                <div className="flex justify-between items-center gap-2 mt-5">
                    <Button
                        icon={<InfoCircleOutlined />}
                        onClick={downloadTemplate}
                        className="text-[#1d234f] rounded h-10 ml-2"
                    >
                        Download CSV Template
                    </Button>
                    <div className='flex justify-center gap-2'>
                        <div className="">
                            <Button
                                icon={<UploadOutlined />}
                                onClick={triggerFileInput}
                                className="text-[#1d234f] rounded h-10"
                            >
                                Upload CSV
                            </Button>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                        </div>
                        <button
                            className="bg-bgBlue text-white py-2 px-5 rounded"
                            onClick={() => setShowInviteUserDialog(true)}
                        >Add New User</button>
                    </div>
                </div>
            }
            {showInviteUserDialog && <InviteUserDialog closeModal={() => setShowInviteUserDialog(false)} fetchUsers={fetchUsers} fetchInvitees={fetchInvitees} />}
        </div >
    )
}

export default UserManagement;
