import * as React from "react";
import { RxDashboard } from "react-icons/rx";
import { PiFiles } from "react-icons/pi";
import { IoLogOutOutline } from "react-icons/io5";
import { ImStatsDots } from "react-icons/im";
import { IoAnalytics } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineWorkspacePremium } from "react-icons/md";

import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import logo2 from '../ulrc_logo.png';
import logo from '../ulrc_logo1.png';
import Footer from "./Footer";
import { useAuth } from "../Auth";
import { PublicationsNav, UnAuthHeader3 } from "./UnAuthHeader";

function Dashboard(props) {

    const { userData, removeUser, subscriptionData } = useAuth()
    const [serviceRole, setServiceRole] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        /// Temp work around for route protection
        if (!userData) navigate("/")
        const _serviceRole = userData?.user?.serviceRole
        setServiceRole(_serviceRole)     
        if((!!_serviceRole && (_serviceRole !== 'superadmin'))) {
            if(!subscriptionData) {
                navigate("/")
            }
        }

        if(!_serviceRole) {
            if(!subscriptionData) {
                navigate("/")
            }
        }

    }, [userData, subscriptionData])

    const logout = (e) => {
        e.preventDefault()
        localStorage.removeItem('user');
        removeUser()
        navigate("/", { replace: true })
        // window.location.reload() 
    }

    const isEditor = userData?.user?.email === "editor@ulrc.go.ug"

    return (
        <main className="bg-white">
            {/* <div className=" -mr-5 px-20 max-md:max-w-full max-md:px-5">
                <UnAuthHeader3 />
            </div> */}
            <section className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <aside className="flex flex-col items-stretch w-[21%] max-md:w-full max-md:ml-0">
                    <header className="bg-bgBlue flex w-full grow flex-col mx-auto px-7 py-12 max-md:mt-8 max-md:px-5">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ba527d48-30ad-466a-8471-14d5cc7f92af?apiKey=345ac80390ed4496b041ffff961f4164&"
                            className="w-[150px] ml-5 max-md:ml-2.5 cursor-pointer"
                            alt="Logo"
                            onClick={() => navigate("/")}
                        />
                        <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white self-stretch flex justify-between gap-3 mt-10 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/info"
                        >
                            <RxDashboard size={18} className={"text-white"} />
                            <div className=" text-base leading-6 self-stretch grow whitespace-nowrap">
                                Dashboard
                            </div>
                        </NavLink>
                        {serviceRole === 'superadmin' && <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white self-stretch flex justify-between gap-3 mt-1.5 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/publications"
                        >
                            <PiFiles size={20} />
                            <div className="text-base leading-6 self-stretch grow whitespace-nowrap">
                                Publications
                            </div>
                        </NavLink>}
                        {serviceRole === 'superadmin' && <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white self-stretch flex justify-between gap-3 mt-1.5 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/prns"
                        >
                            <PiFiles size={20} />
                            <div className="text-base leading-6 self-stretch grow whitespace-nowrap">
                                PRNs
                            </div>
                        </NavLink>}
                        {!!subscriptionData && <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white flex gap-3 mt-1.5 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/user-management"
                        >
                            <FaUsers size={20} />
                            <div className="text-base whitespace-nowrap">
                                Subscription Management
                            </div>
                        </NavLink>}
                        {serviceRole === 'superadmin' &&<NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white flex gap-3 mt-1.5 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/people-management"
                        >
                            <FaUsers size={20} />
                            <div className="text-base whitespace-nowrap">
                                User Management
                            </div>
                        </NavLink>}
                        {/* {serviceRole === 'superadmin' &&<NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white flex gap-3 mt-1.5 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/add-enterprise-user"
                        >
                            <MdOutlineWorkspacePremium size={20} />
                            <div className="text-base whitespace-nowrap">
                                Add Enterprise User
                            </div>
                        </NavLink>} */}
                        
                        {/* <NavLink
                            className={({ isActive }) => {
                                return `items-center ${isActive ? 'bg-red-600' : 'opacity-50'} text-white self-stretch flex justify-between gap-3 mt-1.5 pl-5 py-3.5 rounded-xl max-md:pr-5 cursor-pointer`;
                            }}
                            to="/dashboard/analytics"
                        >
                            <IoAnalytics size={20} />
                            <div className="text-base leading-6 self-stretch grow whitespace-nowrap">
                                Analytics
                            </div>
                        </NavLink> */}
                        <nav
                            className="items-center opacity-50 bg-bgBlue self-stretch flex justify-between gap-3 mt-1.5 mb-[503px] pl-5 py-3.5 rounded-xl max-md:mb-10 max-md:pr-5 cursor-pointer"
                            onClick={logout}
                        >
                            <IoLogOutOutline size={20} className={"text-white"} />
                            <div className="text-white text-base leading-6 self-stretch grow whitespace-nowrap">
                                Logout
                            </div>
                        </nav>

                    </header>
                </aside>
                <section className="flex flex-col items-stretch w-[75%] ml-5 max-md:w-full max-md:ml-0">
                    <Outlet />
                </section>
            </section>
            <Footer />
        </main>
    );
}

export default Dashboard;
