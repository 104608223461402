import React, { useEffect, useState } from 'react'
import { useAuth } from '../Auth'
import { API_URL, BASE_URL } from '../envs'
import axios from 'axios'
import { Spin, Table } from 'antd'
import { toast } from 'react-toastify';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { currentDateFormat } from './CartPayment'

function AddEnterpriseUser() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [duration, setDuration] = useState('');
    const [numUsers, setNumUsers] = useState(0);
    const [isValid, setIsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [prn, setPrn] = useState('');
    const [generatingPrn, setGeneratingPrn] = useState(false);
    const [savingPrn, setSavingPrn] = useState(false);
    const [sendingConfirmation, setSendingConfirmation] = useState(false);

    const handlePhoneNumberChange = (value) => {        
        setPhoneNumber(value);
        setIsValid(isValidPhoneNumber(String(value)) && value.startsWith('+256'));
    };

    const validateForm = () => {
        if (!name || !email || !phoneNumber || !amountPaid || !duration || !numUsers) {
            alert("Please fill in all fields.");
            return false;
        }

        if (!name || !phoneNumber) {
            alert("Please fill in all the fields")
            return false
        }

        if (name.length < 7) {
            alert("Name should be at least 7 characters")
            return false
        }

        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        if (!regex.test(phoneNumber)) {
            alert("Please enter a valid phoneNumber number")
            return false
        }

        if (numUsers < 100) {
            alert("Number of users must be over 100.");
            return false;
        }

        return true;
    }

    const handleRegister = async (event) => {
        event.preventDefault();
    
        if (!validateForm()) return;
    
        try {
            setLoading(true);
    
            let response;
            try {
                setGeneratingPrn(true);
                response = await axios.post(`${BASE_URL}/get_prn`, {
                    "TaxPayerName": name,
                    "AssessmentDate": currentDateFormat(),
                    "MobileNo": phoneNumber.slice(1),
                    "Email": email,
                    "AdditionalFees": "0",
                    "NoOfForms": "1",
                    "Amount": amountPaid,
                    "ExpiryDays": "21",
                    "PaymentBankCode": "DTB",
                    "PaymentMode": "CASH",
                    "PaymentType": "DT",
                    "TaxPayerBankCode": "DTB",
                    "ReferenceNo": "string",
                    "SRCSystem": "ULRC",
                    "GrossAmount": "0",
                    "TIN": "1000943176",
                    "TaxHead": "ULRC100"
                });

                if(response) {
                    toast.success('PRN Generated successfully', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            } catch (error) {
                console.error("Error in first API request:", error);
                throw error;
            } finally {
                setGeneratingPrn(false);
            }

            const myPrn = response.data?.result?.GetPRNResponse?.GetPRNResult?.PRN;
            setPrn(myPrn);
    
            if (response.data) {
                try {
                    setSavingPrn(true)
                    const resp2 = await axios.post(`${API_URL}/prns`, {
                        data: {
                            prn: `${myPrn}`,
                            phoneNumber,
                            userName: name,
                            prnAmount: amountPaid,
                            emailID: email,
                            numberOfUsers: numUsers,
                            packageDuration: `${duration} months`,
                            package: "Enterprise"
                        }
                    });
                    if(resp2) {
                        toast.success('PRN Saved successfully', {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                } catch (error) {
                    console.error("Error in second API request:", error);
                    throw error;
                } finally {
                    setSavingPrn(false);
                }
    
                try {
                    setSendingConfirmation(true)
                    const confirm = await axios.post("https://govnet-api-proxy.vercel.app/prn-confirmation", {
                        "prn": myPrn,
                        "email": email,
                        "name": name,
                        "amount": amountPaid,
                        "package": "Enterprise",
                        "duration": `${duration} months`,
                    });
                    
                    if(confirm){
                        toast.success('PRN Confirmation sent successfully', {
                            position: toast.POSITION.TOP_RIGHT
                        })                    
                    }
                } catch (error) {
                    console.error("Error in third API request:", error);
                    throw error;
                } finally {
                    setSendingConfirmation(false)
                }
            }
    
        } catch (error) {
            console.error("Error in handleRegister:", error);
    
        } finally {
            setLoading(false);
        }        
    };

    const handleClearForm = () => {
        setAmountPaid(0);
        setNumUsers(0);
        setDuration(0);
        setEmail("");
        setPhoneNumber("");
        setName("");
        setPrn("");
        setGeneratingPrn(false);
        setSavingPrn(false);
        setSendingConfirmation(false);
        setLoading(false);
    }

    return (
        <div className="mt-14 px-5 max-md:max-w-full max-md:mt-10 grid grid-cols-1 md:grid-cols-2 gap-10">
            <div>
                <div className="flex justify-between mb-3">
                    <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full">
                        Add Enterprise User
                    </h1>
                </div>
                <form onSubmit={handleRegister} className="space-y-4">
                    <div>
                        <label className="block mb-1">Name:</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full p-2 rounded-md border"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 rounded-md border"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Phone Number:</label>                       
                        <PhoneInput
                            international
                            defaultCountry="UG"
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            className={`w-full p-2 rounded-md border ${isValid ? '' : 'border-red-500'}`}
                            required
                        />
                        {!isValid && (
                            <p className="text-red-500">Please enter a valid Ugandan phone number (+256XXXXXXXXX).</p>
                        )}
                    </div>
                    <div>
                        <label className="block mb-1">Amount Paid:</label>
                        <input
                            type="number"
                            value={amountPaid}
                            onChange={(e) => setAmountPaid(e.target.value)}
                            className="w-full p-2 rounded-md border"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Duration (months):</label>
                        <input
                            type="number"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            className="w-full p-2 rounded-md border"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-1">Number of Users:</label>
                        <input
                            type="number"
                            value={numUsers}
                            onChange={(e) => setNumUsers(e.target.value)}
                            className="w-full p-2 rounded-md border"
                            required
                        />
                    </div>
                    {!prn && <button
                        type="submit"
                        className={`w-full ${loading ? "bg-red-300" : "bg-red-600"} text-white py-2 rounded-md mt-4`}
                        disabled={loading}
                    >
                        {/* {loading ? "Generating PRN" : "Register and Generate PRN"} */}
                        {generatingPrn && "Generating PRN"}
                        {savingPrn && "Saving PRN"}
                        {sendingConfirmation && "Sending Confirmation"}
                        {!loading && "Register and Generate PRN"}
                    </button>}
                    {prn &&  <button
                        onClick={handleClearForm}
                        className='bg-bgBlue text-white py-2 rounded-md w-full'>
                        Create a new subscription
                    </button>}
                </form>
            </div>
            <div className="p-5 bg-gray-100 rounded-lg shadow-md">
                <h2 className="text-xl font-bold mb-2">Enterprise Package</h2>
                <p><strong>Amount:</strong> {parseInt(amountPaid).toLocaleString()}</p>
                <p><strong>Number of Users:</strong> {parseInt(numUsers).toLocaleString()}</p>
                <p><strong>Duration:</strong> {parseInt(duration).toLocaleString()} months</p>

                <h2 className="text-xl font-bold mt-6 mb-2">User Details</h2>
                <p><strong>Name:</strong> {name}</p>
                <p><strong>Email:</strong> {email}</p>
                <p><strong>Phone:</strong> {phoneNumber}</p>

                <h2 className="text-xl font-bold mt-6 mb-2">Payment Reference Number</h2>
                <p><strong>PRN:</strong> {prn}</p>
            </div>
        </div>
    )
}

export default AddEnterpriseUser
